























































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Bop from "@/models/Bop";
import { BopStatus, UserProfilEnum, UserRoleEnum } from "@/utils/Enums";
import SitesServices from "@/services/SitesServices";
import userServices from "@/services/UserService";
import User from "@/models/User";
import UserService from "@/services/UserService";
import EntreprisesServices from "@/services/EntreprisesServices";
import BopModelService from "@/services/BopModelService";
import TypeTravauxService from "@/services/TypeTravauxService";
import BopService from "@/services/BopService";
import { Guid } from "guid-typescript";
import OperationGroup from "@/models/OperationGroup";
const TYPETRAVAUX_MAINTENANCE_KEY = "maintenance";
// const SUPPORTED_BOPMODELS=['NettoyageIndustriel','TuyauteriePlastique','ElectriciteInstrumentation','Echafaudage','CalorifugageTuyauterie','TuyauterieMetallique','SerrurerieCharpentes','PeintureIndustrielle']
@Component({})
export default class GeneralInformationsStep extends Vue {
  @Prop()
  private isEditable!: boolean;
  private get canBeEditedAfterRealised(): boolean {
    return (
      this.$route.query.isEdit !== undefined &&
      this.$route.query.isEdit === "true"
    );
  }
  private bopModelContainerKey: string = "";
  private yesNoOptions: Array<any> = [
    {
      text: this.$t("bopSetup.generalInformations.yes").toString(),
      value: true,
    },
    {
      text: this.$t("bopSetup.generalInformations.no").toString(),
      value: false,
    },
  ];
  private get bopShort(): String {
    const siteName = this.bop.siteName;
    const bopModelName = this.bop.bopModelName?.toString().toLowerCase();

    if (siteName && bopModelName && siteName.length && bopModelName.length)
      return `${siteName} - ${bopModelName} ${
        this.bop.bopNumber ? " # " : ""
      }${this.bop.bopNumber ?? ""}`;

    return this.$t("bopSetup.generalInformations.stepTitle").toString();
  }
  private providerMails: string = "";
  private typeTravaux: Array<any> = [];
  private actifBopModels: any = {};
  private bopModels: Array<any> = [];
  private get language(): string {
    return this.$i18n.locale;
  }
  private get bop() {
    return this.$store.state.bop;
  }
  private set bop(value: Bop) {
    this.$store.commit("changeBop", value);
  }
  private sites: Array<any> = [];
  private entreprises: Array<any> = [];
  private orderGivers: Array<any> = [];
  private currentuser: User = userServices.getCurrentuser();
  private images: any = require.context("@/../public/icons/", false, /\.svg$/);
  private get isArkemaUser() {
    return this.currentuser?.profil?.profilKey === UserProfilEnum.arkemaUser;
  }
  private get bopStatus() {
    return this.$store.state.bopStatuses
      ? this.$store.state.bopStatuses[this.bop.bopStatusKey]
      : "";
  }

  private getIcon(key: string) {
    return this.images(`./icon_${key}.svg`);
  }
  //methods
  private async loadSites() {
    this.sites = (
      await (this.isArkemaUser
        ? SitesServices.getEnabledSites(this.language)
        : SitesServices.getEntrepriseSites(
            this.currentuser.entrepriseId,
            this.language
          ))
    ).data;
    if (this.sites.length === 1) {
      this.bop.siteId = this.sites[0].site.id;
    }
  }
  private async loadEntreprisesBySite(siteId: string) {
    this.entreprises = (
      await EntreprisesServices.getEntreprisesBySite(this.language, siteId)
    ).data;
    if (!this.isArkemaUser) {
      this.bop.entrepriseId = this.currentuser.entrepriseId;
    }
    await this.loadProviderMails();
  }
  private async loadProviderMails() {
    if (!this.bop.entrepriseId) return;
    const entrepriseUsers: Array<any> = (
      await UserService.getContactUsersByEntreprise(
        this.bop.entrepriseId,
        this.language
      )
    ).data;
    if (entrepriseUsers.length) {
      this.providerMails = entrepriseUsers
        .map((u: User) => u.mail)
        .reduce(
          (accumulator: string, mail: string) => `${accumulator}, ${mail}`
        );
    } else {
      this.providerMails = "";
    }
  }
  private disableAllBopModels() {
    for (let bmId in this.actifBopModels) {
      this.actifBopModels[bmId] = false;
    }
  }
  private async handleEnablingBopModels() {
    if (this.bop.siteId && this.bop.entrepriseId) {
      const actifBopModels = (
        await BopModelService.getDeployedBopModelsOnSite(
          this.bop.siteId,
          this.bop.entrepriseId
        )
      ).data;
      this.bopModels.forEach((bopModel: any) => {
        const isActif =
          actifBopModels.findIndex(
            (el: any) => el.key == bopModel.bopModel.id
          ) !== -1;
        // && SUPPORTED_BOPMODELS.includes(bopModel.bopModel.key);
        this.actifBopModels[bopModel.bopModel.id] = isActif;
      });
      if (this.bop.bopModelId && !this.actifBopModels[this.bop.bopModelId]) {
        this.bop.bopModelId = null;
        this.bop.bopModelName = "";
        this.bop.bopModelKey = "";
        this.bop.orderGiverUserId = null;
        this.bop.entrepriseId = null;
        this.bop.operationPrincipalGroups.forEach(
          (o: OperationGroup) => (o.canBeDeleted = true)
        );
      }
      this.bopModelContainerKey = Guid.create().toString();
      // this.$forceUpdate();
    }
  }

  private bopModelClick(bm: any) {
    if (!this.isEditable || !this.actifBopModels[bm.bopModel.id]) return;

    this.$emit("bopmodel-changed", bm);
    this.bopChanged();
  }
  private async laodData() {
    if (this.bop.id === Guid.createEmpty().toString()) {
      const typeTravauxMaintenance = (
        await TypeTravauxService.getTypeTravauxByKey(
          TYPETRAVAUX_MAINTENANCE_KEY,
          this.language
        )
      ).data;
      this.bop.typeTravaux = typeTravauxMaintenance.id;
    }
    await this.loadSites();
    // await this.loadEntreprises();
    this.bopModels = (await BopModelService.getBopModels(this.language)).data;

    this.typeTravaux = (
      await TypeTravauxService.listTypeTravaux(this.language)
    ).data;

    await this.onBopSiteChange();
  }
  private bopChanged() {
    this.$store.commit("changeHasBeenModified", true);
  }
  //hooks
  private created() {
    this.laodData().then(() => {
      this.$nextTick().then(() => {
        this.$emit("fully-loaded");
      });
    });
  }
  private isLoaded = false;
  private mounted() {}
  // watchers

  @Watch("bop.entrepriseId")
  private async onBopEntrepriseChange(newValue: string) {
    await this.loadProviderMails();
    this.handleEnablingBopModels();
  }

  @Watch("bop.siteId")
  private async onBopSiteChange() {
    if (!this.bop.siteId) {
      this.bop.bopModelId = null;
      this.bop.bopModelName = "";
      this.bop.bopModelKey = "";
      this.bop.orderGiverUserId = null;
      this.bop.entrepriseId = null;
      this.bop.operationPrincipalGroups.forEach(
        (o: OperationGroup) => (o.canBeDeleted = true)
      );
      this.disableAllBopModels();
      this.$store.commit("changeSelectedBopModel", null);
      return;
    }
    this.bop.siteName = this.sites.find(
      (st) => st.site.id == this.bop.siteId
    )?.translation.name;
    this.orderGivers = (
      await UserService.getOrderGiverUsersBySite(this.bop.siteId, this.language)
    ).data;
    this.handleEnablingBopModels();
    this.loadEntreprisesBySite(this.bop.siteId);
  }
  @Watch("bop.isSimulation")
  private onIsSimulationChange() {
    if (this.bop.isSimulation) {
      this.bop.bopStatusKey = BopStatus.Simulation;
    } else {
      this.bop.bopStatusKey = BopStatus.Initial;
    }
  }
}
